import { useState } from "react"
import styled from "styled-components"

import {
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
} from "@material-ui/core"

import {
  usePostHomeEventFeedback,
  usePostSmokeFeedbackLegacy,
} from "src/components/EventLog/smokeFeedbackQueries"
import {
  TCSDFeedbackContext,
  usePostCigaretteReportFindingsConfirmed,
  usePostCigaretteReportFindingsInitiated,
} from "src/data/analytics/queries/smokingDetectionAnalyticQueries"
import { useFetchDevice } from "src/data/devices/queries/deviceQueries"
import { IEvent } from "src/data/events/types/eventTypes"
import { THome } from "src/data/homes/types/homeTypes"
import { useOrganization } from "src/data/organizations/hooks/useOrganization"
import { usePatchSmokingDetection } from "src/data/organizations/queries/homeQueries"
import { useStorage } from "src/data/storage/useStorage"
import { useGetUser } from "src/data/user/hooks/useGetUser"
import { useEffectOnce } from "src/hooks/useEffectOnce"
import { langKeys } from "src/i18n/langKeys"
import { useTranslate } from "src/i18n/useTranslate"
import { MDialog, TMDialogProps } from "src/ui/Dialog/MDialog"
import CigaretteIcon from "src/ui/icons/cigarette-smoking.svg"
import { MText } from "src/ui/MText"
import { spacing } from "src/ui/spacing"
import { formatDate } from "src/utils/l10n"
import { localStorageFactory } from "src/utils/storageUtil"
import { Optional } from "src/utils/tsUtil"

const RESPONSES = [
  { label: langKeys.yes, value: "yes" },
  { label: langKeys.no, value: "no" },
  { label: langKeys.feedback_dont_know, value: "unknown" },
] as const
type TResponseType = (typeof RESPONSES)[number]["value"]

type SmokeFeedbackDialogProps = {
  open: TMDialogProps["open"]
  onClose: TMDialogProps["onClose"]
  homeEventFeedback: boolean // signifies whether or not we should use the new feedback endpoint; can be removed once the old event log has been replaced
  feedbackData: TSmokeEventFeedbackData
  context: TCSDFeedbackContext
}

export function SmokeFeedbackDialog({
  open,
  ...props
}: SmokeFeedbackDialogProps) {
  if (!open) return null

  // We rely on conditional rendering here in order to only report opening the
  // dialog once it's actually open, and to avoid haywire useEffects.
  return <OpenedSmokeFeedbackDialog {...props} />
}

export function OpenedSmokeFeedbackDialog({
  onClose,
  feedbackData,
  context,
  homeEventFeedback,
}: Omit<SmokeFeedbackDialogProps, "open">) {
  const { orgId } = useOrganization()
  const { t, langKeys } = useTranslate()
  const [response, setResponse] = useState<TResponseType>("yes")

  const postSmokeFeedbackLegacy = usePostSmokeFeedbackLegacy()
  const postHomeEventFeedback = usePostHomeEventFeedback()
  const postCigaretteReportFindingsInitiated =
    usePostCigaretteReportFindingsInitiated()
  const postCigaretteReportFindingsConfirmed =
    usePostCigaretteReportFindingsConfirmed()
  const patchSmokingDetection = usePatchSmokingDetection({ orgId })

  useEffectOnce(() => {
    postCigaretteReportFindingsInitiated.mutate({ context })
  })

  const {
    clear: clearComment,
    set: setComment,
    data: comment,
  } = useStoredCigaretteFeedback()

  function resetForm() {
    clearComment()
    setResponse("yes")
  }

  function handleConfirm() {
    postCigaretteReportFindingsConfirmed.mutate({ context })

    // 'Dismiss' the alert by setting it idle for 60 minutes:
    patchSmokingDetection.mutate({
      homeId: feedbackData.homeId,
      data: { status: "idle" },
    }) // dismissal is a side-effect, so we don't need to wait for it to finish

    if (homeEventFeedback) {
      return postHomeEventFeedback.mutate(
        {
          home_id: feedbackData.homeId,
          home_event_id: feedbackData.eventId,
          response,
          comment: comment ?? undefined,
        },
        {
          onSuccess() {
            resetForm()
            onClose()
          },
        }
      )
    }

    postSmokeFeedbackLegacy.mutate(
      {
        question_id: "smoking_detected_correctly",
        feedback_item_id: feedbackData.eventId,
        answer: { comment: comment ?? "", response },
        event_type: "smoking_detection_smoking_detected",
      },
      {
        onSuccess() {
          resetForm()
          onClose()
        },
      }
    )
  }

  return (
    <MDialog
      open={true}
      title={t(langKeys.report_findings)}
      onClose={onClose}
      onConfirm={handleConfirm}
      confirmLabel={t(langKeys.submit)}
      hideClose
      loading={
        postSmokeFeedbackLegacy.isLoading || patchSmokingDetection.isLoading
      }
      error={
        postSmokeFeedbackLegacy.isError &&
        t(langKeys.failed_something_went_wrong)
      }
    >
      <DialogContent>
        <SmokeFeedbackInfo feedbackData={feedbackData} />

        <FeedbackBox>
          <MText variant="subtitle">
            {t(langKeys.log_feedback_event_reported_correctly)}
          </MText>

          <RadioGroup
            value={response}
            onChange={(e) => setResponse(e.target.value as TResponseType)}
          >
            {RESPONSES.map((choice) => (
              <FormControlLabel
                key={choice.value}
                value={choice.value}
                control={<Radio />}
                label={t(choice.label)}
              />
            ))}
          </RadioGroup>

          <TextField
            fullWidth
            placeholder={t(langKeys.smoking_detection_feedback_comment_hint)}
            minRows={3}
            multiline
            value={comment ?? ""}
            onChange={(e) => setComment(e.target.value)}
          />
        </FeedbackBox>
      </DialogContent>
    </MDialog>
  )
}

const DialogContent = styled.div`
  display: grid;
  gap: ${spacing.L};
`

const FeedbackBox = styled.div`
  display: grid;
  gap: ${spacing.M};
`

export type TSmokeEventFeedbackData = {
  area: string
  address: string | undefined
  date: string
  timezone: Optional<THome["timezone"]>
  eventId: IEvent["event_id"]
  deviceId: IEvent["device_id"]
  homeId: IEvent["home_id"]
}

function SmokeFeedbackInfo({
  feedbackData,
}: {
  feedbackData: TSmokeEventFeedbackData
}) {
  const user = useGetUser()
  const { t, langKeys } = useTranslate()

  const { orgId } = useOrganization()
  const fetchDeviceName = useFetchDevice({
    orgId,
    deviceId: feedbackData.deviceId ?? "",
    options: {
      enabled: !!feedbackData.deviceId,
      select(data) {
        return data.description
      },
    },
  })
  const deviceName = fetchDeviceName.data

  return (
    <SmokeFeedbackInfoBox>
      <MText variant="bodyS" color="secondary">
        {[
          deviceName,
          feedbackData.area,
          feedbackData.address,
          formatDate({
            date: feedbackData.date,
            timezone: feedbackData.timezone,
            clockType: user.clock_type,
          }),
        ]
          .filter(Boolean)
          .join(" · ")}
      </MText>

      <CigaretteIcon width={24} />
      <MText variant="subtitle">
        {t(langKeys.smoking_detection_alert_title)}
      </MText>

      <MText variant="bodyS" color="secondary">
        {t(langKeys.smoking_detection_smoking_detected_details)}
      </MText>
    </SmokeFeedbackInfoBox>
  )
}

const SmokeFeedbackInfoBox = styled.div`
  padding: ${spacing.M};
  background-color: #e9ecf2;
  border-radius: 8px;

  display: grid;
  grid-template-columns: ${spacing.XL} auto;

  & > *:not(svg) {
    grid-column: 2;
  }
`

const storedCigaretteFeedback = localStorageFactory({
  key: "minut.cigarette_feedback",
})

function useStoredCigaretteFeedback() {
  return useStorage({ storage: storedCigaretteFeedback, initialValue: "" })
}
